import React from "react"
//import { Link } from "gatsby"
import AppContext from "../appcontext"

// const imgBg1 = require('../../../static/img/bg-showcase-1.jpg');
// const imgBg2 = require('../../../static/img/bg-showcase-2.jpg');
// const imgBg3 = require('../../../static/img/bg-showcase-3.jpg');

const AugmentedRealityContent = () => {
	
	return (
	<div id="content">
		{/* Image Showcases */}
		<section className="showcase">
			<div className="container-fluid p-0">
				<AppContext.Consumer>
				{ ({placeData, setPlaceData}) =>
					<>
					
					{placeData.details.map((detail, index) => {
						return (
						<div key={"detail"+index} className="row no-gutters">
							<div className={"col-lg-6 text-white showcase-img"+((index%2==0)?" order-lg-2":"")} 
								style={{ backgroundImage: `url(`+detail.image+`)` }}></div>
							<div className={"col-lg-6 my-auto showcase-text "+((index%2==0)?" order-lg-1":"")}>
								<h2>{detail.title}</h2>
								<p className="lead mb-0">{detail.description}</p>
							</div>
						</div>
						);
					})}
					</>
				}
				</AppContext.Consumer>

				{/* <div className="row no-gutters">
					<div className="col-lg-6 order-lg-2 text-white showcase-img" style={{ backgroundImage: `url(https://360nyc.vunet.us/data/skin/360nyc/img/iphone-HOME.png)` }}></div>
					<div className="col-lg-6 order-lg-1 my-auto showcase-text p-3">
						<h2>Experience New York City</h2>
						<p className="lead mb-0">in a new futuristic way by looking into your phoneâs camera, instead of the map, to find the most popular places of interest all around you. 360 NYC is an Augmented Reality (AR) app that displays places you want to find anywhere you point the phone.</p>
					</div>
				</div>

				<div className="row no-gutters">
					<div className="col-lg-6 text-white showcase-img" style={{ backgroundImage: `url(https://360nyc.vunet.us/data/skin/360nyc/img/iphone-DETAILS.png)` }}></div>
					<div className="col-lg-6 my-auto showcase-text p-3">
						<h2>Hundreds of places</h2>
						<p className="lead mb-0">in multiple categories are available including Empire State Building, Statue of Liberty, Times Square, landmarks, historic sites, museums, parks and many others. Special packages include all places of Central Park and Hidden Gems of New York City. The database of custom selected places is growing continuously for your best experience in Manhattan, Brooklyn, Queens, Bronx and Staten Island.</p>
					</div>
				</div>

				<div className="row no-gutters">
					<div className="col-lg-6 order-lg-2 text-white showcase-img" style={{ backgroundImage: `url(https://360nyc.vunet.us/data/skin/360nyc/img/iphone-SEARCH.png)` }}></div>
					<div className="col-lg-6 order-lg-1 my-auto showcase-text p-3">
						<h2>360 NYC app</h2>
						<p className="lead mb-0">is a panoramic viewer with AR camera that stacks the places of interest within the specified distance. Tapping the place expands additional details for easier navigation, including the map view, directions, contacts, address information and description.</p>
					</div>
				</div> */}

			</div>
		</section>
	</div>
	// <div className="container ng-scope">
	//     <div className="row">
	//         <div className="col-lg-12">
	//             <h1 className="pb-2 mt-4 mb-2 border-bottom">Augmented Reality</h1>
	//         </div>
	//     </div>
	//     <div className="row">
	//         <div className="col-sm-12">
	//             <h3>Learn About Augmented Reality</h3>
	//             <p>Lid est laborum dolo rumes fugats untras. Etharums ser quidem rerum facilis dolores nemis omnis fugats
	//                 vitaes nemo minima rerums unsers sadips amets. Sed ut perspiciatis unde omnis iste natus error sit
	//                 voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
	//                 veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
	//         </div>
	//     </div>
	// </div>

)}

export default AugmentedRealityContent
