import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import AugmentedRealityContent from "../components/content/augmented-reality-apps"
import Footer from "../components/footer"

const AugmentedRealityPage = () => (
  <>
    <SEO title="Augmented Reality" />
    <Nav />
    <AugmentedRealityContent />
    <Footer />
  </>
)

export default AugmentedRealityPage
